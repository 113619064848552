import React from 'react';
import { createRoot } from 'react-dom/client';

import Routes from './routes';

import './index.scss';
import { RecaptchaService } from './services/recaptcha';

const container = document.getElementById('root');
const root = createRoot(container!);

RecaptchaService.init();

root.render(
    <Routes />
);
