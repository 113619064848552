import { Environment } from '../environemnt';

export abstract class RecaptchaService {

    public static readonly siteKey = Environment.value.GOOGLE_RECAPTCHA_ENTERPRISE_SITE_KEY;
    private static isInitialized = false
    
    public static init() {
        if (!this.isInitialized) {
            const script = document.createElement('script');
            script.src = `https://www.google.com/recaptcha/enterprise.js?render=${this.siteKey}`;
            script.onload = () => this.isInitialized = true;
            document.head.appendChild(script);
        }
    }

    public static async getToken(action: string) {

        const token = this.isInitialized
            ? await grecaptcha.enterprise.execute(this.siteKey, { action })
            : null;

        return {
            token,
            action,
            siteKey: this.siteKey,
            asHeaders: () => ({
                'x-type': 'enterprise',
                'x-action': action,
                'x-captcha': token,
                'x-captcha-sitekey': this.siteKey,
            })
        }
    }
}
