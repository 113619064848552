import devEnv from './environment/dev.json';
import hmlEnv from './environment/hml.json';
import prdEnv from './environment/prd.json';

export abstract class Environment {

    public static get value() {
        if (window.location.host.includes('localhost')) return devEnv;
        return [devEnv, hmlEnv, prdEnv].find(env => env.HOST === window.location.host) || prdEnv;
    };

}